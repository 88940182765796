import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { convertTimeToLocal } from "../../Utils/utils";
import defaultPlaceHolder from "../../assets/images/PlaceHolderShows.jpg";


function ScheduleCard(props) {
  const history = useNavigate();
  const replaceImage = (e) => {
    //replacement of broken Image
    e.target.src = defaultPlaceHolder;
  };

  return (
    // <div>
      
            <div className="shows">
              <h3>
                {props?.show?.starttime &&
                  convertTimeToLocal(props?.show?.starttime)}
              </h3>
              <div className="showContainer">
                <div className="leftContainer">
                  <div className="imageContainer">
                    <img
                      src={
                        "https://gizmeon.mdc.akamaized.net/thumbnails/thumbnails/" +
                        props?.show?.thumbnail
                        
                      }
                      onError={(e) => replaceImage(e)}
                      alt="Thumbnail"
                    />
                  </div>
                </div>
                <div className="rightContainer">
                  <h1 className="title"> {props?.show?.video_title?.slice(0,25)}{props?.show?.video_title?.length>25&&"..."} </h1>
                  <p className="description">
                    {props?.show?.video_description?.slice(0,185)}
                    {props?.show?.video_description?.length>185&&"..."}
                  </p>
                  <div className="buttonContainer">
                    <button
                      onClick={() => {
                        history({
                          pathname: "/show",
                          search: encodeURI(`show_id=${props?.show?.show_id}`),
                        });
                      }}
                    >
                      On Demand
                    </button>

                    { (!(props?.earlyFlag))&&
                      ((!props?.show?.schedule_reminded)?
                      <button onClick={()=> props?.remindFunction(props?.show?.id , false , props.index)}>
                      Remind me
                      <img
                        src={require("../../assets/images/bell.png")}
                        alt="Bell"
                      />
                    </button>

                    :

                    <button className="cancelReminder" onClick={()=> props?.remindFunction(props?.show?.id , true , props.index)}>
                      Cancel Reminder
                      <img
                        src={require("../../assets/images/bell.png")}
                        alt="Bell"
                      />
                    </button>)
                    }
                    
                  </div>
                </div>
              </div>
            </div>
            // {" "}
            // {/* // ( shows ) */}
          
    // </div>
  );
}

export default ScheduleCard;
