import React from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

function ShowSlider(props) {
  const navigate = useNavigate();
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <div className="shows">
        <div className="top">
          <h1 className="heading">{props?.showName}</h1>
          <div className="buttonContainer">
            {(props?.shows?.shows?.length >=10)&&
            <button 
            onClick={()=> navigate(`/shows?ID=${props?.shows?.category_id}`)}
            >
              <img src={require("../../assets/images/plus.png")} alt="Plus" />
              More
            </button>
            }
          </div>
        </div>
        <div className="slickSection">
          <Slider {...settings}>
            {props?.shows?.shows?.map((item, key) => (
              <div
                className="episodes"
                key={key}
                onClick={() => {
                  if (item.video_id) {
                    navigate(`/player?video_ID=${item?.video_id}`, {
                      state: { id: item?.video_id },
                    });
                  } else {
                    navigate({
                      pathname: "/show",
                      search: encodeURI(`show_id=${item?.show_id}`),
                    });
                  }
                }}
              >
                <div className="imageContainer">
                  <img
                    src={
                      item?.logo_thumb
                        ? item?.logo_thumb
                        : item?.thumbnail_350_200
                    }
                    alt=""
                  />
                </div>
                <p>{item?.show_name ? item?.show_name : item?.video_title}</p>
                <div className="descriptionContainer">
                <p>{item?.show_name ? item?.show_name : item?.video_title}</p>
                  <span><p>{item?.synopsis}</p></span>
                </div>
              </div>
            ))}

            {/* {props?.shows?.shows?.map((item, key) => (
              <div
                className="episodes"
                key={key}
                onClick={() => {
                  if (item.video_id) {
                    navigate(`/player?video_ID=${item?.video_id}`, {
                      state: { id: item?.video_id },
                    });
                  } else {
                    navigate({
                      pathname: "/show",
                      search: encodeURI(`show_id=${item?.show_id}`),
                    });
                  }
                }}
              >
                <div className="imageContainer">
                  <img
                    src={
                      item?.logo_thumb
                        ? item?.logo_thumb
                        : item?.thumbnail_350_200
                    }
                    alt=""
                  />
                </div>
                <p>{item?.show_name ? item?.show_name : item?.video_title}</p>
              </div>
            ))} */}
          </Slider>
        </div>{" "}
        {/* ( div.whatsNewsSection>slick ) */}
      </div>
    </div>
  );
}

export default ShowSlider;
