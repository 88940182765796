import React, { useEffect, useState } from "react";
import "./showsScreen.css";
import { useSearchParams } from "react-router-dom";

import { service } from "../../../Network/Home/Service";
import { useNavigate } from "react-router-dom";
import Spinner from "../../Spinner/Spinner";
function ShowsScreen() {
  const history = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [sliceCount, setSliceCount] = useState(12);
  const [downArrow, setDownArrow] = useState(true);
  const [showsList, setShowsList] = useState([]);

  const slice = showsList.slice(0, sliceCount);
  useEffect(() => {
    if (sliceCount >= showsList.length) {
      setDownArrow(false);
    } else {
      setDownArrow(true);
    }
  }, [sliceCount, showsList]);

  const loadMore = () => {
    setSliceCount(sliceCount + 12);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    var singleObj = [];
    service
      .showsByCategory(searchParams.get("ID") ? searchParams.get("ID") : 714)
      .then((response) => {
        setLoading(false);
        if (response.success == true && response.data) {
          var data = response.data.shows;
          data.map((item, index) => {
            singleObj.push(item);
          });

          setShowsList(singleObj);
        }
      });
  }, []);

  const listShows = () => {
    return slice.map((item, key) => (
      <li
        key={key}
        onClick={() => {
          history({
            pathname: "/show",
            search: encodeURI(`show_id=${item.show_id}`),
          });
        }}
      >
        <div className="imageContainer">
          <img
            src={
              `https://gizmeon.mdc.akamaized.net/thumbnails/show_logo/` +
              item.logo_thumb
            }
            alt="Shows"
          />
        </div>
        <span>{item.show_name}</span>
        <div className="descriptionContainer">
           <span>{item.show_name}</span>
           <span><p>{item?.synopsis}</p></span>
        </div>
     
      </li>
    ));
  };
  return !loading ? (
    showsList.length > 0 ? (
      <div id="shows">
        <div className="wrapper">
          <h1 className="heading">All Shows</h1>
          <ul>{listShows()}</ul>
          <div className={downArrow ? "bottom" : "no-active"}>
            <div
              className={downArrow ? "downArrowContainer" : "no-active"}
              onClick={loadMore}
            >
              <img
                src={require("../../../assets/images/down-arrow-flat.png")}
                alt="Down-arrow"
              />
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "150px",
          color: "white",
        }}
      >
        No shows found!!
      </div>
    )
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "150px",
      }}
    >
      <Spinner />
    </div>
  );
}

export default ShowsScreen;
