import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Slider from "react-slick";

function NewArrival(param) {
  const history = useNavigate();
  const [Shows, setShows] = useState([]);
  useEffect(() => {
    param && setShows(param.NewArrival.shows);
  }, [param]);

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return Shows ? (
    <div className="whatsNewSection">
      <h1 className="heading">KICCTV On Demand</h1>
      <h3 className="subHeading">What's New</h3>
      {
        console.log(Shows?.length,"shows length")
      }
      <div
        className={Shows?.length > 6 ? "slickSection" : "slickSection center"}
      >
        <Slider {...settings}>
          {Shows.map((item, index) => (
            <div
              className="episodes"
              key={index}
              onClick={() => {
                history({
                  pathname: "/show",
                  search: encodeURI(`show_id=${item.show_id}`),
                });
              }}
            >
              <div className="imageContainer">
                <img
                  src={item.logo_thumb}
                  alt={require("../../assets/images/show-episode-thumbnail.png")}
                />
              </div>
              <div className="descriptionContainer">
                <span>{item.show_name}</span>
                <p>{item?.synopsis}</p>
              </div>
              <p>{item.show_name}</p>
            </div>
          ))}
        </Slider>
      </div>
      {/* ( #main>div.whatsNewsSection>slick ) */}
    </div>
  ) : (
    ""
  );
}

export default NewArrival;
