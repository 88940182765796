import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";

import "./singleShowScreen.css";
import { service } from "../../../Network/Home/Service";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Spinner from "../../Spinner/Spinner";
import {
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

function SingleShowScreen() {
  const navigate = useNavigate();
  const [sliceCount, setSliceCount] = useState(10);
  const [downArrow, setDownArrow] = useState(true);
  const [showsList, setShowsList] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showDetails, setshowDetails] = useState([]);
  const slice = showsList?.slice(0, sliceCount);
  const [toMyList, settoMyList] = useState(0);
  const [shareActive, setShareActive] = useState(false);
  const [showID, setshowID] = useState();
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  // share refs
  let shareIconRef = useRef(null);
  let shareDivRef = useRef(null);

  useEffect(() => {
    let handleOutClick = (e) => {
      if (!shareIconRef?.current?.contains(e.target)) {
        if (!shareDivRef?.current?.contains(e.target)) {
          setShareActive(false); //outside click
        }
      }
    };

    document.addEventListener("mousedown", handleOutClick);
    return () => {
      document.removeEventListener("mousedown", handleOutClick);
    };
  }, [shareDivRef]);

  useEffect(() => {
    if (sliceCount >= showsList?.length) {
      setDownArrow(false);
    } else {
      setDownArrow(true);
    }
  }, [sliceCount, showsList]);

  const loadMore = () => {
    if (sliceCount < showsList?.length) {
      setSliceCount(sliceCount + sliceCount);
    }
    if (
      sliceCount >= showsList?.length ||
      showsList?.length - 1 == sliceCount ||
      showsList?.length - 2 == sliceCount ||
      showsList?.length - 3 == sliceCount ||
      showsList?.length - 4 == sliceCount
    ) {
      setDownArrow(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setshowID(searchParams.get("show_id"));
    service.getShowDetails(searchParams.get("show_id")).then((response) => {
      setShowsList(response?.data?.videos[0]?.videos);
      setshowDetails(response?.data);
      settoMyList(response?.data?.watchlist_flag);
    });
  }, []);

  const listShows = () => {
    if (slice) {
      return slice?.map((item, key) => (
        <li
          key={key}
          onClick={() => {
            navigate(`/player?video_ID=${item.video_id}`, {
              state: { id: item.video_id, detail: showsList },
            });
          }}
        >
          <div className="imageContainer">
            <img
              src={
                `https://gizmeon.mdc.akamaized.net/thumbnails/thumbnails/` +
                item?.thumbnail_350_200
              }
              alt="Shows"
            />
          </div>
          <span>{item.video_title}</span>
          <div className="descriptionContainer">
           <span>{item.video_title}</span>
           <p>{item?.video_description}</p>
        </div>
        </li>
      ));
    } else {
      <div></div>;
    }
  };

  const addToMyList = (id) => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    let userId = service.getCookie("userId");
    if (isLoggedIn && userId) {
      service.addToMyPlayList(id, 1).then((response) => {
        if (response.success === true) {
          settoMyList(1);
        }
      });
    } else {
      navigate(`/login`);
    }
  };
  const removeFromMyList = (id) => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    let userId = service.getCookie("userId");
    if (isLoggedIn && userId) {
      service.addToMyPlayList(id, 0).then((response) => {
        if (response.success === true) {
          settoMyList(0);
        }
      });
    } else {
      navigate(`/login`);
    }
  };
  return showsList?.length > 0 ? (
    <div id="singlePage">
      <div className="wrapper">
        <div id="features">
          <Slider {...settings}>
            <div className="featureSection">
              <div className="background">
                <img
                  style={{ background: 'black', opacity: '0.5' }}

                  src={
                    "https://gizmeon.mdc.akamaized.net/thumbnails/show_logo/" +
                    showDetails?.logo_thumb
                  }
                  alt="Thumbnail"
                />
              </div>
              <div className="containers">
                <div className="leftContainer">
                  <div className="imageContainer">
                    <img
                      src={
                        "https://gizmeon.mdc.akamaized.net/thumbnails/show_logo/" +
                        showDetails?.logo_thumb
                      }
                      alt="Thumbnail"
                    />
                  </div>
                </div>
                <div className="rightContainer">
                  <h1 className="title">{showDetails?.show_name}</h1>
                  <p>{showDetails?.synopsis}</p>
                  <div className="buttonContainer">
                    <button
                      className={toMyList === 1 ? "removeList" : "listButton"}
                      onClick={() => {
                        toMyList === null || toMyList === 0
                          ? addToMyList(showDetails?.show_id)
                          : removeFromMyList(showDetails?.show_id);
                      }}
                    >
                      {toMyList === 1 ? (
                        <div className="list">
                          Remove From List
                          <img
                            src={
                              require("../../../assets/images/minus.svg")
                                .default
                            }
                            alt="CrossIcon"
                          />
                        </div>
                      ) : (
                        <div>
                          Add To List
                          <img
                            src={require("../../../assets/images/icon-plus.png")}
                            alt="PlusIcon"
                          />{" "}
                        </div>
                      )}
                    </button>
                    <button onClick={() => setShareActive(!shareActive)} className="share" ref={shareIconRef}>
                      Share
                      <img
                        src={
                          require("../../../assets/images/share_icon.svg")
                            .default
                        }
                        alt="PlayIcon"
                      />
                      <div ref={shareDivRef}
                        className={
                          shareActive
                            ? "shareContainer active"
                            : "shareContainer no-active"
                        }
                      >
                        <div className="facebook" >
                          <FacebookShareButton style={{ justifyContent: 'center' }}
                            url={
                              "https://kicctv.tv/show?show_id=" +
                              showID
                            }
                          >
                            <FacebookIcon size={30} round={false} />
                          </FacebookShareButton>
                        </div>
                        <div className="twitter">
                          <TwitterShareButton style={{ justifyContent: 'center' }}
                            url={
                              "https://kicctv.tv/show?show_id=" +
                              showID
                            }
                          >
                            <TwitterIcon size={30} round={false} />
                          </TwitterShareButton>
                        </div>
                        <div className="whatsapp">
                          <WhatsappShareButton style={{ justifyContent: 'center' }}
                            url={
                              "https://kicctv.tv/show?show_id=" +
                              showID
                            }
                          >
                            <WhatsappIcon size={30} round={false} />
                          </WhatsappShareButton>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>

      <div id="shows">
        <div className="wrapper">
          <h1 className="heading">Episodes</h1>
          <ul>{listShows()}</ul>
          <div className={downArrow ? "bottom" : "no-active"}>
            <div
              className={downArrow ? "downArrowContainer" : "no-active"}
              onClick={loadMore}
            >
              <img
                src={require("../../../assets/images/down-arrow-flat.png")}
                alt="Down-arrow"
              />
            </div>
          </div>
        </div>
      </div>
    </div> // ( #singlePage )
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "150px",
      }}
    >
      <Spinner />
    </div>
  );
}

export default SingleShowScreen;
